import axios from 'axios'

const Productos = {
    listarCategoriasTabStock(params){
        return axios(`categorias/tendero/stock`,{params:params})
    },
    listarProductosTabStock(params){
        return axios(`productos/tendero/stock`,{params: params})
    },
    listarProductosTabRotacion(params){
        return axios(`productos/tendero/rotacion`,{params: params})
    },
    listarCategoriasTabRotacion(params){
        return axios(`categorias/tendero/rotacion`,{params: params})
    },
    detalleProducto(params){
        return axios(`productos/tendero/detalle`,{params})
    },
    agregarAlCarrito(form){
        return axios.post(`productos/tendero/add/${form.id_producto}`,form)
    },
    updateCantidadProducto(form){
        return axios.put(`productos/tendero/update`,form)
    },
    setValorProductoTienda(form){
        return axios.put(`productos/tendero/precio/update`,form)
    },
    restablecerValoresProductosTienda(idTienda){
        return axios.put(`productos/tendero/${idTienda}/precio/restore`)
    }

}

export default Productos

