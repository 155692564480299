<template>
    <section class="px-2">
        <div class="d-flex mx-0 pt-2">
            <div class="lat-categorias br-t-12 mr-3">
                <div class="tabs-productos row mx-0 border-bottom" style="height:40px;">
                    <div class="col f-14 cr-pointer d-middle-center text-center" :class="tabStock?'border-b-black text-general':'text-general2'" @click="verStock()">
                        Stock
                    </div>
                    <div class="col f-14 cr-pointer d-middle-center text-center" :class="tabRotacion?'border-b-black text-general':'text-general2'" @click="verRotacion()">
                        Rotacion
                    </div>
                    <div class="col f-14 cr-pointer d-middle-center text-center" :class="tabActivos?'border-b-black text-general':'text-general2'" @click="verInventario()">
                        Activos
                    </div>
                    <div class="col f-14 cr-pointer d-middle-center text-center" :class="stockMin?'border-b-black text-general':'text-general2'" @click="stock_bajo()">
                        Stock bajo
                    </div>
                </div>
                <div v-show="!stockMin" class="row mx-0 py-3">
                    <div class="col-12 my-auto d-middle">
                        <el-input v-model="buscar" prefix-icon="icon-search f-17" size="medium" placeholder="Buscar Producto..." class="br-20 mr-2" />
                    </div>
                </div>
                <div class="col px-0 br-10 overflow-auto custom-scroll" style="width:400px;height:calc(100vh - 265px);">
                    <infoCategoria :ver-info-rotacion="tabRotacion" :info-inventario="infoInventario" :info-rotacion="infoRotacion" />
                    <div class="row mx-0 px-3 mb-3 mt-5">
                        <div class="col-auto f-600 text-general f-20">
                            Categorías
                        </div>
                    </div>

                    <!-- Nuevo -->
                    <infoCategoria v-if="!inventario && !stockMin" :ver-info-rotacion="verRotacion" :info-inventario="infoInventario" :info-rotacion="infoRotacion" />
                    <div class="row mx-0 px-3 mb-3 mt-5">
                        <div class="col-auto f-600 text-general f-20">
                            Categorías
                        </div>
                        <template v-if="!inventario && !stockMin">
                            <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 select-categorias">
                                <el-select v-model="id_categoria" placeholder="Selecciona una categoria" class="w-100 br-20">
                                    <el-option
                                    v-for="item in categorias"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                            </div>
                            <div class="col-auto px-0 ml-xl-0 ml-lg-0 ml-md-auto ml-sm-auto">
                                <div class="bg-light-f5 border text-general2 px-2 py-1 br-12 f-18 cr-pointer" @click="descargar">
                                    <i class="icon-file-download-outline" />
                                    Catálago
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- Nuevo -->

                    <div
                    v-for="(c, idx) in categorias" :key="idx"
                    class="row mx-4 d-middle border-bottom cr-pointer position-relative"
                    style="height:54px;"
                    @click="setCategoria(c.id, idx)"
                    >
                        <div class="position-absolute" style="left:-24px;" :class="`mx-2 px-1 h-80 br-5 ${activo==idx?'bg-general':''}`" />
                        <div class="col-9 px-2 tres-puntos f-15">
                            <div class="row mx-0 f-600">
                                <p class="tres-puntos text-general">{{ c.nombre }}</p>
                            </div>
                        </div>
                        <div :class="`br-10 px-1 text-center f-17 my-auto ${activo==idx?'bg-general3 text-white':'bg-whitesmoke'}`" style="min-width:40px;">
                            <span class="f-12">{{ c.cant_productos }}/{{ agregarSeparadoresNumero(c.total_productos_categoria) }}</span>
                        </div>
                        <i :class="`icon-angle-right f-18 ${activo==idx?'text-general':'text-g-light'}`" />
                    </div>
                    <content-loader v-show="loading" />

                    <!-- sin coincidencias -->
                    <div v-if="buscar.length>0 && categorias.length === 0" class="row mx-0 my-4 align-content-center justify-center h-90">
                        <div class="col-9 text-center">
                            <p class="mb-0 text-muted2 f-17 f-600">
                                No hemos encontrado resultados.
                            </p>
                            <div class="row mx-0 justify-center f-60">
                                <img src="/img/icons/product-1.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div v-else-if="categorias.length === 0" class="row mx-0 my-4 align-content-center justify-center h-90">
                        <div class="text-center f-17 f-600 d-middle-center flex-column" style="width:225px;">
                            <p>Vamos a crear el primer producto</p>
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/mercado.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 br-tr-12 menu-prinicipal" style="width: calc(100vw - 500px);">
                <listadoProductosTendero v-if="!tabActivos" :categoria="id_categoria" :tab="tabActiva" />
                <catalogo v-else :categoria="id_categoria" />
            </div>
        </div>
        <!-- Partials -->
        <modalEliminar ref="modalEliminarProd" :titulo="titulo_eliminar" @eliminar="eliminarStock">
            <div class="row mx-0 mb-2 justify-center text-center">
                {{ msg_eliminar }}
            </div>
        </modalEliminar>
    </section>
</template>

<script>
import Productos from "~/services/productos/productos"
import ProductosTienda from "~/services/productos/productosTendero"
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Productos'}),
    components: {
        listadoProductosTendero: () => import('~/pages/productos/tendero-vip/listadoProductosTendero'),
        catalogo: () => import('~/pages/productos/catalogo'),
        infoCategoria: () => import('~/pages/productos/admin/components/infoCategoria.vue'),
        //modalDescargarCatalogo:() => import('~/pages/configurar/admin/partials/modalDescargarCatalogo'),
    },
    data(){
        return {
            id_tienda_ruta: parseInt(this.$route.params.id_tienda),
            stockMin: false,
            tabActivos: false,
            tabStock: true,
            tabRotacion: false,
            titulo_eliminar: '',
            msg_eliminar: '',
            activo: -1,
            id_categoria:null,
            categorias:[],
            loading:false,
            infoInventario: {
                total_productos: 0,
                valor_inventario: 0,
            },
            infoRotacion: {
                total_productos_modificados: 0,
                ganancia_predeterminada: 0,
                mostrar_card: false,
            },
        }
    },
    computed:{
        ...mapGetters({
            query: 'productos/productos/query',
        }),
        id_tienda(){
            return this.$route.params.id_tienda
        },
        buscar: {
            // getter
            get: function(){
                return this.query
            },
            // setter
            set: function(newValue){
                this.$store.commit('productos/productos/set_query',newValue)

                this.id_categoria = null
                this.activo = -1

                if(newValue.length > 2){
                    const addInfo = () => {
                        this.lista_categorias()
                    }
                    this.delay(addInfo)

                }
                if(newValue.length === 0){
                    const addInfo = () => {
                        this.lista_categorias()
                    }
                    this.delay(addInfo)
                }
            }
        },
        tabActiva(){
            if(this.tabStock) return 'stock'
            if(this.tabRotacion) return 'rotacion'
            if(this.tabActivos) return 'activos'
            if(this.stockMin) return 'stockBajo'
            return 'stock'
        }
    },
    watch:{
        tabActiva(){
            this.id_categoria = null
            this.activo = -1,
            this.$store.commit('productos/productos/set_id_producto', null)
        }
    },
    mounted(){
        this.lista_categorias()
    },
    methods: {
        async lista_categorias(){
            try {
                this.loading = true
                let params = {
                    id_tienda: this.id_tienda_ruta? this.id_tienda_ruta :this.$usuario.tienda.id,
                    caso:2,
                    query:this.query
                }
                const {data} = await ProductosTienda.listarCategoriasTabStock(params)
                this.categorias = data.categorias
                this.infoInventario = data.info_inventario

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async eliminarStock(){
            try {
                var {data} = await Productos.eliminarStock(this.id_tienda_ruta)

                this.$refs.modalEliminarProd.toggle()
                this.notificacion('Mensaje','Stock eliminado correctamente','success')
            } catch (e){
                this.error_catch(e)
            }
        },
        verStock(){
            this.lista_categorias()
            this.tabStock = true
            this.tabActivos = false
            this.tabRotacion = false
            this.stockMin = false
            this.verRotacion = false
        },
        verInventario(){
            this.lista_categorias()
            this.tabActivos = true
            this.tabRotacion = false
            this.stockMin = false
            this.tabStock = false
        },
        async stock_bajo(){
            try {
                this.tabActivos = false
                this.tabRotacion = false
                this.tabStock = false
                this.stockMin = true

                let params = {
                    id_categoria: this.categoria,
                    id_cedis: this.cedis,
                    id_tienda: this.id_tienda_ruta? this.id_tienda_ruta :this.$usuario.tienda.id,
                    caso: 2
                }
                this.loading = true

                const {data} = await Productos.categorias_bajas_stock(params)
                this.categorias = data

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async verRotacion(){
            try {
                this.loading = true
                this.tabRotacion = true
                this.tabActivos = false
                this.tabStock = false
                this.stockMin = false

                const params = {
                    id_tienda: this.id_tienda_ruta? this.id_tienda_ruta :this.$usuario.tienda.id,
                }

                const {data} = await ProductosTienda.listarCategoriasTabRotacion(params)
                this.categorias = data.categorias
                this.infoRotacion = data.info_rotacion
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        setCategoria(id, key){
            if(id < 1) return

            this.id_categoria = id
            this.activo = key
        },
        descargar(){
            let datos = {
                cedis: false,
                id_cedis: null,
                leechero: true,
                id_leechero: this.$tienda.id
            }
            this.$refs.modalDescargarCatalogo.toggle(datos);
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #ffffff;
}

.lat-categorias{
    background: #ffffff;
    width: 400px;
    box-shadow: 0px 1px 4px #00000014;
}
</style>
